@font-face {
  font-family: SamsungOne;
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/SamsungOne-800_v1.0.ttf") format("ttf"),
    url("../fonts/SamsungOne-800_v1.0.ttf");
}

@font-face {
  font-family: SamsungOne;
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/SamsungOne-700_v1.0.ttf") format("ttf"),
    url("../fonts/SamsungOne-700_v1.0.ttf");
}

@font-face {
  font-family: SamsungOne;
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/SamsungOne-600_v1.0.ttf") format("ttf"),
    url("../fonts/SamsungOne-600_v1.0.ttf");
}

@font-face {
  font-family: SamsungOne;
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/SamsungOne-500_v1.0.ttf") format("ttf"),
    url("../fonts/SamsungOne-500_v1.0.ttf");
}

@font-face {
  font-family: SamsungOne;
  font-style: normal;
  font-weight: 450;
  src: url("../fonts/SamsungOne-450_v1.0.ttf") format("ttf"),
    url("../fonts/SamsungOne-450_v1.0.ttf");
}

@font-face {
  font-family: SamsungOne;
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/samsungone-400.ttf") format("ttf"),
    url("../fonts/samsungone-400.ttf");
}

@font-face {
  font-family: SamsungOne;
  font-style: normal;
  font-weight: light;
  src: url("../fonts/samsungone-400.ttf") format("ttf"),
    url("../fonts/samsungone-400.ttf");
}
@font-face {
  font-family: SamsungSharpSans;
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/SamsungSharpSans-Bold.ttf") format("ttf"),
    url("../fonts/SamsungSharpSans-Bold.ttf");
}
