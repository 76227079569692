// Core Components
@import "core/variables";
@import "core/mixins";
@import "core/fileupload";
@import "core/keyframes";

$size__site_content_width: 1024px;

$media_queries: (
  "mobile": "only screen and (max-width: 667px)",
  "tablet":
    "only screen and (min-width: 668px) and (max-width: $size__site_content_width)",
  "desktop": "only screen and (min-width: ($size__site_content_width + 1))",
  "retina2":
    "only screen and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi)",
  "retina3":
    "only screen and (-webkit-min-device-pixel-ratio: 3) and (min-resolution: 288dpi)",
  "landscape": "screen and (orientation:landscape) ",
  "portrait": "screen and (orientation:portrait) ",
);

@mixin for_breakpoint($breakpoints) {
  $conditions: ();
  @each $breakpoint in $breakpoints {
    // If the key exists in the map
    $conditions: append(
      $conditions,
      #{inspect(map-get($media_queries, $breakpoint))},
      comma
    );
  }

  @media #{$conditions} {
    @content;
  }
}
// Core Plugins
@import "core/misc";
@import url(../css/samsungOne.css);
// @import "plugins/plugin-datetime-picker";
@import "plugins/plugin-nouislider";
@import "plugins/plugin-react-datetime";
@import "plugins/plugin-react-image-gallery";
@import "plugins/plugin-react-slick";
@import "plugins/plugin-react-tagsinput";
